<template>
	<div class="report-container">
		<div class="report-title-box">行业动态订单</div>
		<div class="divider"></div>
		<div class="report-content">
			<el-tabs v-model="activeName" @tab-click="handleClick" class="tabs-box">
				<el-tab-pane name="">
					<span slot="label">全部订单</span>
				</el-tab-pane>
				<el-tab-pane name="630102">
					<span slot="label">已支付</span>
				</el-tab-pane>
				<el-tab-pane name="630101">
					<span slot="label" class="flex">
						待支付
						<div class="noPay" v-if="noPay">
							{{noPay>99?'99+':noPay}}
						</div> 
					</span>
				</el-tab-pane>
				<el-tab-pane name="630104">
					<span slot="label">已关闭</span>
				</el-tab-pane>
			</el-tabs>
			<div v-if="total == 0" style="margin-top: 150px">
				<empty name="暂无数据"></empty>
			</div>
			<div class="report-box" v-show="total != 0">
				<el-table :data="tableData" height="590px" style="width: 100%" header-cell-class-name="table_header">
					<el-table-column prop="index" label="序号" width="50"></el-table-column>
					<el-table-column prop="orderNo" label="订单编号" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="goodsName" label="购买详情" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="payAmount" label="支付金额（元）" width="120">
						<template slot-scope="scope">￥{{ calcNum(scope.row.payAmount) }}</template>
					</el-table-column>
					<el-table-column prop="payDate" label="支付时间" width="160">
						<template slot-scope="scope">{{ scope.row.payDate || "-" }}</template>
					</el-table-column>
					<el-table-column prop="" label="支付状态" width="100">
						<template slot-scope="scope">
							<span :class="scope.row.orderState == '630102' ? 'payed' : 'unpay'">{{
								getState(scope.row.orderState) }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="" label="">
						<template slot-scope="scope">
							<el-button size="medium" type="text" v-if="scope.row.orderState == '630102'"
								@click.stop="toResearchDetail(scope.row)">查看</el-button>
							<el-button size="medium" type="text" v-else-if="scope.row.orderState == '630104'"
								@click.stop="deleteOrder(scope.row)">删除订单</el-button>
							<el-button size="medium" type="text" v-else-if="scope.row.orderState == '630101'"
								@click.stop="closedOrder(scope.row)">交易取消</el-button>
							<span v-else>-</span>
							<el-button size="medium" type="text" v-show="scope.row.orderState == '630101'"
								@click.stop="toPay(scope.row)">去支付</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="pagination-box">
				<pagination ref="pagination" :total="total" @change="paginChange" v-show="total != 0"></pagination>
			</div>
		</div>
		<!-- 支付弹窗 -->
		<pay-modal ref="payModal"></pay-modal>
	</div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
import Modal from "@/components/Modal";
import { validateTax } from "@/utils/validate.js";
import PayModal from "@/components/payModal";
export default {
	components: {
		Pagination,
		Empty,
		Modal,
		PayModal,
	},
	data() {
		// 手机固话校验
		const validatePhone = (rule, value, callback) => {
			const moblie = "^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-9])|(18[0-9])|166|198|199|191|195|193|(147))\\d{8}$";
			const tel = /^(\d{3,4}-)?\d{7,8}$/;
			if (!value) {
				callback();
			} else if (!new RegExp(moblie).test(value) && !tel.test(value) && value !== "") {
				callback(new Error("请输入格式正确的联系方式"));
			} else {
				callback();
			}
		};
		// 邮箱校验
		const validateEmail = (rule, value, callback) => {
			if (!/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(value)) {
				callback(new Error("请输入格式正确的邮箱"));
			} else {
				callback();
			}
		};
		// 银行账户  数字输入 validateAccount
		const validateAccount = (rule, value, callback) => {
			const account = /^\d+$/;
			if (!value) {
				callback();
			} else if (!account.test(value) && value !== "") {
				callback(new Error("请输入格式正确的银行账户"));
			} else {
				callback();
			}
		};
		return {
			total: 0,
			tableData: [],
			params: {
				pageNum: 1,
				pageSize: 10,
			},
			dataPO: {},
			invoiceTypeList: [
				{
					label: "增值税电子普通发票",
					value: "82",
				},
				// {
				//   label: "增值税专用发票",
				//   value: "81",
				// },
			],
			subjectTypeList: [
				{
					label: "企事业单位",
					value: "60461002",
				},
				{
					label: "个人",
					value: "60461001",
				},
			],
			subTypeFlag: 1,
			invoiceContentList: [
				{
					label: "信息技术服务",
					value: "60651001",
				},
				// {
				// 	label: "商品明细",
				// 	value: "60651002",
				// },
			],
			rules: {
				corpname: [{ required: true, message: "请输入单位名称", trigger: "blur" }],
				unitTaxNo: [
					{ required: true, message: "请输入单位税号", trigger: "blur" },
					{
						validator: validateTax,
					},
				],
				// invoiceType: [
				//   { required: true, message: "请选择您的发票类型", trigger: "change" },
				// ],
				subjectType: [{ required: true, message: "请选择您的主体类型", trigger: "change" }],
				invoiceContent: [{ required: true, message: "请选择您的发票内容", trigger: "change" }],
				peopleLookUp: [{ required: true, message: "请输入个人抬头", trigger: "blur" }],
				mailbox: [
					{ required: true, message: "请输入邮箱", trigger: "blur" },
					{
						validator: validateEmail,
					},
				],
				contactNumber: [
					{
						trigger: "blur",
					},
					{
						validator: validatePhone,
					},
				],
				bankAccount: [
					{
						trigger: "blur",
					},
					{
						validator: validateAccount,
					},
				],
			},
			activeName: "",
			noPay:0,
		};
	},
	computed: {},
	created() { },
	mounted() {
		this.getPage();
		if(this.$route.query&&this.$route.query.noPay){
			this.noPay=this.$route.query.noPay*1
		}
	},
	methods: {
		handleClick() {
			this.params.orderState = this.activeName * 1 || null;
			this.params.pageNum = 1;
			this.params.pageSize = 10;
			this.tableData = [];
			this.getPage();
		},
		// 去支付
		toPay(row) {
			console.log("row", row);
			this.$refs.payModal.open = true;
			this.$refs.payModal.orderData = { orderId: row.orderId, info: row.goodsName, price: row.payAmount, reportPay: true,type:'行业动态', ...row };
			this.$refs.payModal.open = true;
			this.$refs.payModal.payOrder();
		},
		// 判断支付状态
		getState(state) {
			if (state == "630101") {
				return "支付待确认";
			} else if (state == "630102") {
				return "支付完成";
			} else if (state == "630103") {
				return "支付失败";
			} else if (state == "630104") {
				return "交易关闭";
			} else if (state == "630105") {
				return "支付待确认";
			}
		},
		// 获取列表
		getPage() {
			this.$api.personalCenter
				.industryOrderPage(this.params)
				.then(res => {
					res.rows.forEach((item, index) => {
						item.index = (this.params.pageNum - 1) * this.params.pageSize + index + 1;
					});
					this.tableData = res.rows;
					this.total = res.total;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 保留两位小数方法
		calcNum(val) {
			return Number(val).toFixed(2);
		},
		// 切换分页
		paginChange(page, pageSize) {
			this.params.pageNum = page;
			this.params.pageSize = pageSize;
			this.getPage();
		},
		// 预览
		toResearchDetail(row) {
			let type;
			let industryDynamicId = row.goodsId;
			if (row.reportType == "620301") {
				type = 1;
			} else {
				type = 2;
			}
			this.$api.industry
				.getReadAndDownload({
					industryDynamicId,
				})
				.then(res => {
					let data = res.data;
					let item = JSON.parse(row.orderDesc)
					if (data.read == "10011001") {
						// 有阅读权限
						let url = this.$router.resolve({
							path: `/industry/industry-detail?type=1&id=${item.industryDynamicId}&fileId=${item.fileId}`,
						});
						window.open(url.href, "_blank");
					} else {
						// 没有阅读权限
						let url = this.$router.resolve({
							path: `/industry/industry-detail?type=1&id=${item.industryDynamicId}&fileId=${item.fileId}`,
						});
						window.open(url.href, "_blank");
					}
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 取消支付
		closedOrder(row) {
			this.$confirm("确认取消该支付吗？", "提示", {
				confirmButtonText: "确认取消",
				cancelButtonText: "取消",
				type: "warning",
			}).then(() => {
				this.$api.personalCenter
					.industryOrderClosedPay(row.orderId)
					.then(() => {
						this.$message({
							type: "success",
							message: "取消成功!",
						});
						this.params.pageNum = 1;
						this.params.pageSize = 10;
						this.tableData = [];
						this.getPage();
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					});
			});
		},
		// 删除订单
		deleteOrder(row) {
			this.$confirm("确认删除该订单吗？", "提示", {
				confirmButtonText: "确认删除",
				cancelButtonText: "取消",
				type: "warning",
			}).then(() => {
				this.$api.personalCenter
					.deleteIndustryOrder(row.orderId)
					.then(() => {
						this.$message({
							type: "success",
							message: "取消删除!",
						});
						this.params.pageNum = 1;
						this.params.pageSize = 10;
						this.tableData = [];
						this.getPage();
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					});
			});
		},
		// 切换主体事件
		handleChangeSubjectType(val) {
			if (val == "60461001") {
				this.subTypeFlag = 2;
				this.$refs.form.clearValidate("unitTaxNo");
				this.$refs.form.clearValidate("corpname");
				this.rules["unitTaxNo"] = [];
				this.rules["corpname"] = [];
				this.rules["peopleLookUp"] = [{ required: true, message: "请输入个人抬头", trigger: "blur" }];
				this.$forceUpdate();
			} else if (val == "60461002") {
				this.subTypeFlag = 1;
				this.rules["unitTaxNo"] = [
					{ required: true, message: "请输入单位税号", trigger: "blur" },
					{
						validator: validateTax,
					},
				];
				this.rules["corpname"] = [{ required: true, message: "请输入单位名称", trigger: "blur" }];
				this.rules["peopleLookUp"] = [];
				this.$refs.form.clearValidate("peopleLookUp");
				this.$forceUpdate();
			}
			this.$forceUpdate();
		},
		// 关闭弹窗时重置表单
		resetFields() {
			this.$refs.form.resetFields();
			this.dataPO = {};
			this.subTypeFlag = 1;
			this.money = 0;
			// this.moneys = [];
		},

	},
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
